<template>
  <base-card>
    <v-card-title>
      <v-btn
        color="primary"
        @click="onAddNewAddOn"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t("network.createNewAddOn") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="addOnList"
        :items-per-page="paginationLimit"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2"
      >
        <template v-slot:item.text="{item}">
          <span class="font-weight-bold">{{ item.text }}</span>
        </template>

        <template v-slot:item.detailAddOns="{item}">
          <ol class="pl-3">
            <li
              v-for="(detailAddOn, index) in item.detailAddOns"
              :key="index"
              class="my-3"
            >
              <span>{{ $t('network.optionText') }}: {{ detailAddOn.text }}</span><br>
              <span>NRC: {{ detailAddOn.nrc }}</span><br>
              <span>MRC: {{ detailAddOn.mrc }}</span><br>
            </li>
          </ol>
        </template>

        <template v-slot:item.isActive="{item}">
          <v-chip
            v-if="item.isActive"
            class="px-4"
            color="primary white--text"
          >
            <v-icon left>
              mdi-access-point-check
            </v-icon>
            {{ $t('common.active') }}
          </v-chip>
          <v-chip
            v-else
            class="px-4"
            color="danger white--text"
          >
            <v-icon left>
              mdi-block-helper
            </v-icon>
            {{ $t('common.inActive') }}
          </v-chip>
        </template>

        <template v-slot:item.action="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ma-1"
                fab
                x-small
                color="success"
                @click.native="onUpdateAddOn(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common.edit") }} {{ $t("network.addOn") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <manage-add-ons-dialog
      v-model="isShowUpdateAddonsDialog"
      :add-on="selectedAddOn"
      @update-add-on="createUpdateAddOn"
      @cancel="closeAddOnDialog"
    />
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import _ from 'lodash'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import ManageAddOnsDialog from './ManageAddOnsDialog'

  export default {
    metaInfo: {
      title: 'Manage Add-Ons',
    },

    components: {
      ManageAddOnsDialog,
    },

    data() {
      return {
        addOnList: [],
        selectedAddonIndex: -1,
        selectedAddOn: {
          id: -1,
          type: 'checkbox',
          text: '',
          nrc: 0,
          mrc: 0,
          detailAddOns: [],
          isActive: true,
        },
        backupSelectedAddOn: {},
        isShowUpdateAddonsDialog: false,
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [{
          value: 'index',
          text: this.$t('common.number'),
          width: 70,
        }, {
          value: 'text',
          text: this.$t('network.mainText'),
          width: 320,
        }, {
          value: 'nrc',
          text: 'NRC',
          width: 120,
        }, {
          value: 'mrc',
          text: 'MRC',
          width: 120,
        }, {
          value: 'detailAddOns',
          text: this.$t('network.detailAddOns'),
        }, {
          value: 'isActive',
          text: this.$t('common.status'),
          width: 180,
        }, {
          text: 'Action',
          value: 'action',
          width: 100,
        }],
      }
    },

    watch: {
      currentPage(val) {
        this.currentPage = val
        this.loadAddOns()
      }
    },

    mounted () {
      this.loadAddOns()
    },

    methods: {
      ...mapActions({
        fetchAddOnList: 'fetchAddOnList',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadAddOns() {
        const pagination = {
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchAddOnList(pagination)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.addOnList = response.rows.map((item, index) => {
            return {
              ...item,
              index: (this.currentPage - 1) * this.paginationLimit + index + 1,
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      onAddNewAddOn() {
        this.selectedAddOn = {
          id: -1,
          type: 'checkbox',
          text: '',
          nrc: 0,
          mrc: 0,
          detailAddOns: [],
          isActive: true,
        }
        this.backupSelectedAddOn = _.cloneDeep(this.selectedAddOn)
        this.isShowUpdateAddonsDialog = true
      },

      onUpdateAddOn(addOn) {
        this.selectedAddOn = {
          id: addOn.id,
          type: addOn.type,
          text: addOn.text,
          nrc: addOn.nrc,
          mrc: addOn.mrc,
          detailAddOns: _.cloneDeep(addOn.detailAddOns),
          isActive: addOn.isActive,
        }
        this.backupSelectedAddOn = _.cloneDeep(this.selectedAddOn)
        this.isShowUpdateAddonsDialog = true
      },

      createUpdateAddOn() {
        this.isShowUpdateAddonsDialog = false
        const payload = {
          type: this.selectedAddOn.type,
          text: this.selectedAddOn.text,
          nrc:
            this.selectedAddOn.type === 'checkbox'
              ? Number(this.selectedAddOn.nrc)
              : 0,
          mrc:
            this.selectedAddOn.type === 'checkbox'
              ? Number(this.selectedAddOn.mrc)
              : 0,
          detailAddOns:
            this.selectedAddOn.type === 'checkbox'
              ? []
              : this.selectedAddOn.detailAddOns.map(item => ({
                text: item.text,
                nrc: Number(item.nrc),
                mrc: Number(item.mrc),
              })),
          isActive: this.selectedAddOn.isActive,
        }

        if (this.selectedAddOn.id === -1) {
          this.createAddOn(payload)
        } else {
          this.updateAddOn(payload)
        }
      },

      async createAddOn(payload) {
        this.setLoadingText(`${this.$t('network.creatingAddOn')}...`)
        this.setLoading(true)

        try {
          await this.$store.dispatch('createAddOn', payload)

          showSuccessDialog(this.$t('network.createdAddOnSuccessfully'))
          this.loadAddOns()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async updateAddOn(payload) {
        this.setLoadingText(`${this.$t('network.updatingAddOn')}...`)
        this.setLoading(true)
        try {
          await this.$store.dispatch('updateAddOn', {
            id: this.selectedAddOn.id,
            ...payload,
          })

          showSuccessDialog(this.$t('network.updatedAddOnSuccessfully'))
          this.loadAddOns()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      closeAddOnDialog() {
        this.selectedAddOn = _.cloneDeep(this.backupSelectedAddOn)
        this.isShowUpdateAddonsDialog = false
      },
    }
  }
</script>
