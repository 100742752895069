<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="900"
    content-class="manage-addons-dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="addOn.id === -1 ? $t('network.addNewAddon') : $t('network.updateAddOns')"
        />
        <v-spacer />
        <v-btn
          color="white"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="px-6 pt-6 pb-0"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="3" class="text-right pt-2 pb-0 font-weight-bold">
              {{ $t("network.mainText") }}*
            </v-col>
            <v-col cols="8" class="py-0">
              <v-text-field
                v-model="addOn.text"
                single-line
                outlined
                dense
                :rules="requiredRules"
                class="mt-0 py-0"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="text-right py-0 pt-1 font-weight-bold">
              {{ $t("common.status") }}
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-checkbox
                v-model="addOn.isActive"
                hide-details
                class="mt-0 pb-3"
                :label="addOn.isActive ? $t('common.active') : $t('common.inActive')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="text-right py-0 font-weight-bold">
              {{ $t("network.addOnType") }}
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-radio-group
                v-model="addOn.type"
                row
                hide-details
                class="mt-0 pt-0"
              >
                <v-radio
                  :label="$t('network.checkbox')"
                  value="checkbox"
                  hide-details
                />

                <v-radio
                  :label="$t('network.radioGroup')"
                  value="radioGroup"
                  hide-details
                />
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="details-addon-header">
            <span class="font-weight-bold text-16">{{ $t("network.detailInfo") }}</span>
            <v-btn
              v-if="addOn.type === 'radioGroup'"
              class="orange darken-4 white--text"
              small
              @click="addDetailItem"
            >
              {{ $t("network.addDetailItem") }}
            </v-btn>
          </div>

          <template v-if="addOn.type === 'checkbox'">
            <v-row class="pt-6">
              <v-col cols="3" class="text-right pt-2 pb-0 font-weight-bold">
                NRC*
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  v-model="addOn.nrc"
                  type="number"
                  single-line
                  outlined
                  dense
                  :rules="requiredRulesWithZero"
                  class="mt-0 py-0"
                />
              </v-col>
              <v-col cols="3" class="text-right pt-2 pb-0 font-weight-bold">
                MRC*
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  v-model="addOn.mrc"
                  type="number"
                  single-line
                  outlined
                  dense
                  :rules="requiredRulesWithZero"
                  class="mt-0 py-0"
                />
              </v-col>
            </v-row>
          </template>

          <template v-if="addOn.type === 'radioGroup'">
            <div
              v-for="(detailItem, index) in detailAddOns"
              :key="index"
              class="detail-addon-item px-4"
              :class="index === 0 ? 'pt-4' : ''"
            >
              <div class="add-on-title d-flex align-center justify-space-between">
                <span class="font-weight-bold">{{ $t("network.addOn") }}{{ index + 1 }}</span>
                <v-icon
                  @click="deleteDetailAddOn(index)"
                  color="orange darken-3"
                >
                  mdi-trash-can-outline
                </v-icon>
              </div>

              <v-row class="pt-4">
                <v-col cols="3" class="text-right pt-2 pb-0 font-weight-bold">
                  {{ $t("network.optionText") }}*
                </v-col>
                <v-col cols="8" class="py-0">
                  <v-text-field
                    v-model="detailItem.text"
                    single-line
                    outlined
                    dense
                    :rules="requiredRules"
                    class="mt-0 py-0"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" class="text-right pt-2 pb-0 font-weight-bold">
                  NRC*
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field
                    v-model="detailItem.nrc"
                    type="number"
                    single-line
                    outlined
                    dense
                    :rules="requiredRulesWithZero"
                    class="mt-0 py-0"
                  />
                </v-col>
                <v-col cols="3" class="text-right pt-2 pb-0 font-weight-bold">
                  MRC*
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field
                    v-model="detailItem.mrc"
                    type="number"
                    single-line
                    outlined
                    dense
                    :rules="requiredRulesWithZero"
                    class="mt-0 py-0"
                  />
                </v-col>
              </v-row>
            </div>
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2 mx-6 pt-4">
        <v-btn
          class="primary px-8 mr-4"
          :disabled="!validForm"
          @click.native="submitForm"
        >
          {{ $t("common.save") }}
        </v-btn>

        <v-btn
          class="error px-8"
          @click.native="$emit('cancel')">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },

      addOn: {
        type: Object,
        required: false,
        default: () => {
          return {
            id: -1,
            type: 'checkbox',
            text: '',
            nrc: 0,
            mrc: 0,
            isActive: true,
            detailAddOns: [],
          }
        },
      },
    },

    data() {
      return {
        detailAddOns: [],
        requiredRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
        requiredRulesWithZero: [
          value => !!value || Number(value) === 0 || this.$t('error.requiredField'),
          value => Number(value) >= 0 || this.$t('error.invalidCost'),
        ],
        validForm: true,
      }
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    watch: {
      dialog(value) {
        if (!value) {
          return
        }

        this.detailAddOns = (this.addOn && this.addOn.detailAddOns) || []
      },
    },

    methods: {
      addDetailItem() {
        this.detailAddOns.push({
          text: '',
          nrc: 0,
          mrc: 0,
        })
      },

      deleteDetailAddOn(index) {
        this.detailAddOns.splice(index, 1)
      },

      submitForm() {
        if (this.addOn.type === 'radioGroup' && this.detailAddOns.length < 2) {
          showErrorDialog(
            this.$t('common.error'),
            this.$t('network.needToAddAtLeastDetailOptions', { count: 2 })
          )
          return
        }

        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.$emit('update-add-on')
      },
    }
  }
</script>

<style lang="scss">
.manage-addons-dialog {
  .details-addon-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 2px;
    margin-top: 20px;
    border-style: solid;
    border-width: 0 0 thin 0;
    border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
  }

  .add-on-title {
    border-style: solid;
    border-width: 0 0 thin 0;
    border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
  }

  .v-card__actions {
    border-style: solid;
    border-width: thin 0 0 0;
    border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
  }
}
</style>
